import { createTheme } from '@mui/system';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#00bcd4',
            contrastText: '#fff',
            hoveredText: '#555',
        },
        secondary: {
            main: '#ff9800',
            contrastText: '#fff',
        },
        text: {
            primary: '#000',
            secondary: '#fff',
            grey: '#ccc',
        },
    },
    spacing: (factor: number) => `${factor * 8}px`,
});
