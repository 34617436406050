export enum Colors {
    white = '#fff',
    black = '#000',
    grey10 = '#fafafa',
    grey20 = '#f5f5f5',
    grey30 = '#eeeeee',
    grey40 = '#e0e0e0',
    grey50 = '#bdbdbd',
    grey60 = '#9e9e9e',
    grey70 = '#757575',
    grey80 = '#616161',
    grey90 = '#424242',
    grey100 = '#212121',

    themedBlue = '#0088a2',
    themedGreen = '#009657',
    themedYellow = '#fec92f',

    error = '#d0021b',

    facebook = '#4267B2',
}
