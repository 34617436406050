import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem, TreeView } from '@mui/lab';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@mui/material';
import { useState } from 'react';
import useSites from './useSites';
import { useTree } from './useTree';

const SiteTreeView = () => {
    const { sites } = useSites();

    const [sitesCopy, setSitesCopy] = useState([
        ...sites.map((site) => ({ ...site, children: [...site.children] })),
    ]);
    const [selected, setSelected] = useState('');
    const [titleDialogOpen, setTitleDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState<'edit' | 'create'>('create');
    const [title, setTitle] = useState('');

    const {
        addNode,
        moveNodeUp,
        moveNodeDown,
        removeNode,
        saveNodes,
        renameNode,
    } = useTree({
        title,
        setTitle,
        selected,
        setSelected,
        sitesCopy,
        setSitesCopy,
    });

    const isParent = () => {
        if (selected === 'root') return true;
        return sitesCopy.find((site) => site.path === selected) !== undefined;
    };

    return (
        <Grid container>
            <Dialog
                open={titleDialogOpen}
                onClose={() => {
                    setTitleDialogOpen(false);
                }}
            >
                <DialogTitle>Vložte názov novej podstánky</DialogTitle>
                <DialogContent>
                    <TextField
                        id='title'
                        label='Názov'
                        variant='standard'
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        color='error'
                        onClick={() => {
                            setTitleDialogOpen(false);
                        }}
                    >
                        Zrušiť
                    </Button>
                    <Button
                        onClick={() => {
                            dialogType === 'create' ? addNode() : renameNode();
                            setTitleDialogOpen(false);
                        }}
                    >
                        Uložiť
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item xs={12}>
                <Box display='flex' justifyContent='center' alignItems='center'>
                    <TreeView
                        aria-label='file system navigator'
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        defaultExpanded={[
                            'root',
                            ...sites.map((site) => site.path),
                        ]}
                        onNodeFocus={(event, nodeId) => {
                            setSelected(nodeId);
                        }}
                        sx={{
                            height: 240,
                            flexGrow: 1,
                            maxWidth: 400,
                            overflowY: 'auto',
                        }}
                    >
                        <TreeItem nodeId='root' label='Web'>
                            {sitesCopy.map((site) => (
                                <TreeItem
                                    key={site.path}
                                    nodeId={site.path}
                                    label={site.title}
                                >
                                    {site.children &&
                                        site.children.map((child) => (
                                            <TreeItem
                                                key={child.path}
                                                nodeId={child.path}
                                                label={child.title}
                                            />
                                        ))}
                                </TreeItem>
                            ))}
                        </TreeItem>
                    </TreeView>
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    paddingTop: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <Button
                    variant='outlined'
                    color='primary'
                    disabled={!isParent() || !selected}
                    onClick={() => {
                        setDialogType('create');
                        setTitleDialogOpen(true);
                    }}
                    sx={{
                        marginRight: '1rem',
                    }}
                >
                    Pridať
                </Button>
                <Button
                    variant='outlined'
                    color='primary'
                    disabled={selected === 'root' || !selected}
                    onClick={() => {
                        setDialogType('edit');
                        setTitleDialogOpen(true);
                    }}
                    sx={{
                        marginRight: '1rem',
                    }}
                >
                    Premenovať
                </Button>
                <Button
                    variant='outlined'
                    color='error'
                    disabled={selected === 'root' || !selected}
                    onClick={() => {
                        removeNode();
                    }}
                    sx={{
                        marginRight: '1rem',
                    }}
                >
                    Vymazať
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    paddingTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Button
                    variant='outlined'
                    color='primary'
                    disabled={selected === 'root' || !selected}
                    onClick={() => {
                        moveNodeUp();
                    }}
                    sx={{
                        marginRight: '1rem',
                    }}
                >
                    <ArrowDropUpIcon />
                </Button>
                <Button
                    variant='outlined'
                    color='primary'
                    disabled={selected === 'root' || !selected}
                    onClick={() => {
                        moveNodeDown();
                    }}
                    sx={{
                        marginRight: '1rem',
                    }}
                >
                    <ArrowDropDownIcon />
                </Button>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    paddingTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Button
                    variant='outlined'
                    color='error'
                    onClick={() => {
                        setSitesCopy([...sites]);
                    }}
                    sx={{
                        marginRight: '1rem',
                        marginLeft: '-1rem',
                    }}
                >
                    Reset
                </Button>
                <Button
                    variant='outlined'
                    onClick={() => {
                        saveNodes();
                    }}
                >
                    Uložiť
                </Button>
            </Grid>
        </Grid>
    );
};
export default SiteTreeView;
