import { setSitesConfig } from '../../api/firestore';
import { Site } from '../../types/site';
import { titleToPath } from '../../utils/titleToPath';

type Props = {
    title: string;
    setTitle: (title: string) => void;
    selected: string;
    setSelected: (selected: string) => void;
    sitesCopy: Site[];
    setSitesCopy: (sitesCopy: Site[]) => void;
};

export const useTree = (props: Props) => {
    const { title, setTitle, selected, setSelected, sitesCopy, setSitesCopy } =
        props;

    const addNode = () => {
        const newSite: Site = {
            path: '',
            title,
            children: [],
        };

        if (selected === 'root') {
            newSite.path = titleToPath(title);
            setSitesCopy([...sitesCopy, newSite]);
            setSelected('');
            setTitle('');
            return;
        }

        const parent: Site | undefined = sitesCopy.find(
            (site) => site.path === selected
        );

        newSite.path = titleToPath(title, parent?.path);

        parent!.children.push(newSite);

        setSitesCopy([...sitesCopy]);
        setSelected('');
        setTitle('');
    };

    const moveNodeUp = () => {
        const parent: Site | undefined = sitesCopy.find(
            (site) => site.path === selected
        );
        if (parent) {
            const index = sitesCopy.findIndex((site) => site.path === selected);
            if (index > 0) {
                const temp = sitesCopy[index - 1];
                sitesCopy[index - 1] = sitesCopy[index];
                sitesCopy[index] = temp;
            }
        } else {
            let parent: Site;

            sitesCopy.forEach((site) => {
                site.children?.forEach((child) => {
                    if (child.path === selected) {
                        parent = site;
                    }
                });
            });

            if (parent!) {
                const index = parent.children!.findIndex(
                    (site) => site.path === selected
                );
                if (index > 0) {
                    const temp = parent.children![index - 1];
                    parent.children![index - 1] = parent.children![index];
                    parent.children![index] = temp;
                }
            }
        }
        setSitesCopy([...sitesCopy]);
    };

    const moveNodeDown = () => {
        const parent: Site | undefined = sitesCopy.find(
            (site) => site.path === selected
        );
        if (parent) {
            const index = sitesCopy.findIndex((site) => site.path === selected);
            if (index < sitesCopy.length - 1) {
                const temp = sitesCopy[index + 1];
                sitesCopy[index + 1] = sitesCopy[index];
                sitesCopy[index] = temp;
            }
        } else {
            let parent: Site;

            sitesCopy.forEach((site) => {
                site.children?.forEach((child) => {
                    if (child.path === selected) {
                        parent = site;
                    }
                });
            });

            if (parent!) {
                const index = parent.children!.findIndex(
                    (site) => site.path === selected
                );
                if (index < parent.children!.length - 1) {
                    const temp = parent.children![index + 1];
                    parent.children![index + 1] = parent.children![index];
                    parent.children![index] = temp;
                }
            }
        }

        setSitesCopy([...sitesCopy]);
    };

    const renameNode = () => {
        const parent: Site | undefined = sitesCopy.find(
            (site) => site.path === selected
        );

        if (parent) {
            parent.title = title;
            parent.path = titleToPath(title);
        } else {
            sitesCopy.forEach((site) => {
                site.children?.forEach((child) => {
                    if (child.path === selected) {
                        child.title = title;
                        child.path = titleToPath(title, site.path);
                    }
                });
            });
        }

        setSitesCopy([...sitesCopy]);
        setSelected('');
        setTitle('');
    };

    const removeNode = () => {
        const newSites = sitesCopy.filter((site) => site.path !== selected);

        newSites.forEach((site) => {
            if (site.children) {
                site.children = site.children.filter(
                    (child) => child.path !== selected
                );
            }
        });

        console.log(newSites);

        setSitesCopy([...newSites]);
        setSelected('');
    };

    const saveNodes = async () => {
        setSitesConfig(sitesCopy);
    };

    return {
        addNode,
        moveNodeUp,
        moveNodeDown,
        renameNode,
        removeNode,
        saveNodes,
    };
};
