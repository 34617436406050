import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useFilePicker } from 'use-file-picker';
import { editPost } from '../../api/firestore';
import { uploadPhotoToStorage } from '../../api/storage';
import { theme } from '../../themes/theme';
import { Post } from '../../types/post';

type Props = {
    postId: string;
    pagePath: string;
    post: Post;
    setPost: (post: Post) => void;
    setIsLoading: (isLoading: boolean) => void;
};

const ImagePicker = (props: Props) => {
    const { postId, pagePath, post, setPost, setIsLoading } = props;

    const [openFileSelector, { filesContent, loading, clear }] = useFilePicker({
        readAs: 'DataURL',
        multiple: true,
        accept: 'image/*',
    });

    useEffect(() => {
        if (filesContent.length > 0) {
            (async () => {
                setIsLoading(true);
                const urls = await uploadPhotoToStorage(filesContent);
                const newImages = [
                    ...(post!.images || []),
                    ...urls.map((url: string) => ({ imageURL: url })),
                ];

                clear();

                const res = await editPost(postId!, {
                    ...post!,
                    images: newImages,
                });

                if (res) {
                    setPost({
                        ...post!,
                        images: newImages,
                    });
                }
                setIsLoading(false);
            })();
        }
    }, [filesContent]);

    return (
        <Button
            variant='outlined'
            onClick={() => {
                console.log('add image');
                openFileSelector();
            }}
        >
            <AddCircleIcon
                sx={{
                    marginRight: theme.spacing(1),
                }}
            />
            Pridať fotografiu
        </Button>
    );
};

export default ImagePicker;
