import { Box, Grid, Typography } from '@mui/material';
import React, { ComponentProps } from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../themes/Colors';
import { Post as PostType } from '../types/post';

type Props = ComponentProps<typeof Box> & {
    post: PostType;
};

const GalleryPost = (props: Props) => {
    const { id, body, images } = props.post;
    const { sx } = props;

    const formatWord = (count: number) => {
        switch (count) {
            case 1:
                return 'ďalšia fotka';
            case 2:
                return 'ďalšie fotky';
            case 3:
                return 'ďalšie fotky';
            case 4:
                return 'ďalšie fotky';
            default:
                return 'ďalších fotiek';
        }
    };

    return (
        <Box
            component={NavLink}
            to={id!}
            sx={{
                textDecoration: 'none',
                color: 'black',
                '&:hover': {
                    color: Colors.themedGreen,
                },
                transition: 'color 0.2s ease-in-out',
                ...sx,
            }}
        >
            <div dangerouslySetInnerHTML={{ __html: body }}></div>
            <Grid container spacing={2}>
                {images &&
                    images?.length > 0 &&
                    images
                        .filter((el, index) => index <= 2)
                        .map((image, index) => (
                            <Grid item sm={4} xs={12} key={image.imageURL}>
                                <img
                                    src={image.imageURL}
                                    alt=''
                                    width={'100%'}
                                />

                                {images.length > 3 && index === 0 && (
                                    <Typography variant='body1'>
                                        + {images.length - 3}{' '}
                                        {formatWord(images.length - 3)}
                                    </Typography>
                                )}
                            </Grid>
                        ))}
            </Grid>
        </Box>
    );
};

export default GalleryPost;
