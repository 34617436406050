import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getPosts } from '../api/firestore';
import PageAnimate from '../components/animations/PageAnimate';
import useAuth from '../components/auth/useAuth';
import CenteredCircularProgress from '../components/CenteredCircularProgress';
import GalleryPost from '../components/GalleryPost';
import PicturePost from '../components/PicturePost';
import Post from '../components/Post';
import useSites from '../components/sites/useSites';
import { theme } from '../themes/theme';
import { Post as PostType } from '../types/post';
import { sortByDate } from '../utils/DateSort';

type Props = {
    route: any;
};

const TemplatePostPage = (props: Props) => {
    const { route } = props;

    const { isLoggedIn } = useAuth();

    const { posts, setPosts } = useSites();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);
        getPosts(route.path).then((posts: any) => {
            setPosts(posts);
            setLoading(false);
        });
    }, [route]);

    if (loading) {
        return <CenteredCircularProgress />;
    }

    const postTypeSwitch = (post: PostType) => {
        switch (post.type) {
            case 'gallery':
                return <GalleryPost post={post} />;
            case 'fullscreenPhoto':
                return <PicturePost post={post} />;
            default:
                return <Post post={post} />;
        }
    };

    return (
        <PageAnimate>
            <Container maxWidth='md'>
                <Box
                    sx={{
                        paddingBottom: theme.spacing(4),
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant='h3' mt={theme.spacing(4)}>
                                {route.title}
                            </Typography>
                        </Grid>
                        {isLoggedIn && (
                            <Box
                                sx={{
                                    padding: theme.spacing(2),
                                }}
                            >
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        console.log('add post');
                                    }}
                                    component={NavLink}
                                    to={`/new?path=${route.path}`}
                                >
                                    <AddCircleOutlineIcon
                                        sx={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    />
                                    Nový Príspevok
                                </Button>
                            </Box>
                        )}
                        {posts
                            .filter(
                                (post: PostType) =>
                                    !post.is_hidden || isLoggedIn
                            )
                            .sort(sortByDate)
                            .map((post: any) => (
                                <Grid item xs={12} key={post.id}>
                                    <Divider />
                                    {postTypeSwitch(post)}
                                </Grid>
                            ))}
                    </Grid>
                </Box>
            </Container>
        </PageAnimate>
    );
};

export default TemplatePostPage;
