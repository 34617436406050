import { Box, CircularProgress } from '@mui/material';

const CenteredCircularProgress = () => {
    return (
        <Box
            width={'100vw'}
            height={'100vh'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <CircularProgress size='4rem' />
        </Box>
    );
};

export default CenteredCircularProgress;
