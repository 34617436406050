import { motion } from 'framer-motion';
import React from 'react';

type Props = {
    children: React.ReactNode;
};

const PageAnimate = (props: Props) => {
    const { children } = props;
    return (
        <motion.div
            initial='initial'
            animate='in'
            exit='out'
            variants={{
                initial: {
                    opacity: 0,
                },
                in: {
                    opacity: 1,
                },
                out: {
                    opacity: 0,
                },
            }}
        >
            {children}
        </motion.div>
    );
};

export default PageAnimate;
