import { Box, Grid, Typography } from '@mui/material';
import { ComponentProps } from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../themes/Colors';

import { Post as PostType } from '../types/post';

type Props = ComponentProps<typeof Box> & {
    post: PostType;
};

const Post = (props: Props) => {
    const { id, body, created_at, images, tags } = props.post;
    const { sx } = props;

    return (
        <Box
            component={NavLink}
            to={id!}
            sx={{
                textDecoration: 'none',
                color: 'black',
                '&:hover': {
                    color: Colors.themedGreen,
                },
                transition: 'color 0.2s ease-in-out',
                ...sx,
            }}
        >
            <Grid container spacing={2}>
                <Grid item sm={8} xs={12}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `${body.slice(0, 600)}${
                                body.length > 600 ? '...' : ''
                            }`,
                        }}
                    ></div>
                    {/* <Grid
                        item
                        xs={12}
                        sx={{
                            paddingTop: '1rem',
                        }}
                    >
                        <Typography variant='caption'>
                            {created_at.toDate().toLocaleDateString()}
                        </Typography>
                    </Grid> */}
                </Grid>
                <Grid item sm={4} xs={12}>
                    {images && images?.length > 0 && (
                        <>
                            <img
                                src={images[0].imageURL}
                                alt=''
                                width={'100%'}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Post;
