import {
    Button,
    Container,
    Grid,
    Icon,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { loginWithEmailAndPassword, signOutUser } from '../api/auth';
import PageAnimate from '../components/animations/PageAnimate';
import useAuth from '../components/auth/useAuth';
import SiteTreeView from '../components/sites/SiteTreeView';
import { Colors } from '../themes/Colors';

const AdminLogin = () => {
    const { isLoggedIn, setIsLoggedIn } = useAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    return (
        <PageAnimate>
            <Container maxWidth='md'>
                <Box
                    sx={{
                        paddingBottom: '2rem',
                    }}
                >
                    <Grid
                        container
                        spacing={3}
                        direction='column'
                        alignItems='center'
                        justifyContent='center'
                        style={{
                            paddingTop: '2rem',
                        }}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant='h4'
                                noWrap
                                sx={{
                                    mr: 2,
                                    display: 'flex',
                                    textDecoration: 'none',
                                    color: Colors.grey80,
                                    fontWeight: 'light',
                                }}
                            >
                                <Icon
                                    sx={{
                                        display: 'flex',
                                        mr: 1,
                                        alignSelf: 'center',
                                        width: '2.5rem',
                                        height: '2.5rem',
                                    }}
                                >
                                    <img src='/logo.svg' alt='logo' />
                                </Icon>
                                <Typography
                                    variant='h4'
                                    sx={{
                                        color: Colors.themedBlue,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    hrdí
                                </Typography>
                                &nbsp;malačania
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='h5' noWrap>
                                Admin prihlásenie
                            </Typography>
                        </Grid>
                        {!isLoggedIn ? (
                            <>
                                {error ? (
                                    <Grid item xs={12}>
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                color: Colors.error,
                                            }}
                                        >
                                            {error}
                                        </Typography>
                                    </Grid>
                                ) : null}
                                <Grid item xs={12} style={{ width: '80%' }}>
                                    <TextField
                                        id='email'
                                        label='Email'
                                        variant='outlined'
                                        type='email'
                                        fullWidth
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ width: '80%' }}>
                                    <TextField
                                        id='password'
                                        label='Heslo'
                                        variant='outlined'
                                        type='password'
                                        fullWidth
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant='outlined'
                                        type='submit'
                                        onClick={() => {
                                            loginWithEmailAndPassword(
                                                email,
                                                password
                                            )
                                                .then(() => {
                                                    setIsLoggedIn(true);
                                                    setEmail('');
                                                    setPassword('');
                                                    setError('');
                                                })
                                                .catch((error) => {
                                                    setError(
                                                        'Nesprávne meno alebo heslo'
                                                    );
                                                });
                                        }}
                                    >
                                        Prihlásiť sa
                                    </Button>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <Button
                                        variant='outlined'
                                        onClick={() => {
                                            signOutUser().then(() => {
                                                setIsLoggedIn(false);
                                            });
                                        }}
                                    >
                                        Odhlásiť sa
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <SiteTreeView />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Box>
            </Container>
        </PageAnimate>
    );
};

export default AdminLogin;
