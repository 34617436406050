import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Button,
    Container,
    Grid,
    ImageList,
    ImageListItem,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { deletePost, editPost, getPostById } from '../api/firestore';
import { theme } from '../themes/theme';
import { Image } from '../types/image';
import { Post } from '../types/post';
import useAuth from './auth/useAuth';
import FullScreenImageViewer from './FullScreenImageViewer';
import useSites from './sites/useSites';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { deletePhotoFromStorage } from '../api/storage';
import DeleteDialog from './dialogs/DeleteDialog';
import ImagePicker from './images/ImagePicker';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { Timestamp } from 'firebase/firestore';
import { Moment } from 'moment';
import CenteredCircularProgress from './CenteredCircularProgress';

const PostDetail = () => {
    const { isLoggedIn } = useAuth();
    const { postId } = useParams();
    const { posts } = useSites();

    const location = useLocation();

    const path = location.pathname.split('/')[1];

    const [post, setPost] = useState<Post | undefined>(undefined);
    const [initialIndex, setInitialIndex] = useState<number | null>(null);
    const [date, setDate] = useState<Date | null | any>(new Date());

    const [body, setBody] = useState<string>('');

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [deleteDialogPhotoOpen, setDeleteDialogPhotoOpen] =
        useState<boolean>(false);

    const [photoToDelete, setPhotoToDelete] = useState<string | undefined>(
        undefined
    );

    const [isEditable, setIsEditable] = useState<boolean>(false);

    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const post = posts.find((post: Post) => post.id === postId);
        if (post && (!post.is_hidden || isLoggedIn)) {
            setPost(post);
            setBody(post.body);
            setDate(post?.created_at.toDate());
            return;
        }

        getPostById(postId!).then((post: Post) => {
            if (!post.is_hidden || isLoggedIn) {
                setPost(post);
                setDate(post?.created_at.toDate());
                setBody(post.body);
            }
        });
    }, [posts, postId]);

    if (!post) {
        return null;
    }

    return (
        <>
            <DeleteDialog
                open={deleteDialogOpen}
                onClose={() => {
                    setDeleteDialogOpen(false);
                }}
                onConfirm={async () => {
                    const res = await deletePost(postId!);

                    if (res) {
                        window.location.href = `/${path}`;
                    }

                    setDeleteDialogOpen(false);
                }}
                title='Vymazať príspevok'
                content='Naozaj chcete vymazať tento príspevok? Táto akcia je
                nevratná.'
            />
            <DeleteDialog
                open={deleteDialogPhotoOpen}
                onClose={() => {
                    setDeleteDialogPhotoOpen(false);
                }}
                onConfirm={async () => {
                    const newImages = post.images!.filter(
                        (image: Image) => image.imageURL !== photoToDelete
                    );

                    const res = await editPost(postId!, {
                        images: newImages,
                    });

                    if (res) {
                        deletePhotoFromStorage(photoToDelete!).then(
                            (deleteRes: boolean) => {
                                if (deleteRes) {
                                    setPost({ ...post, images: newImages });
                                    setPhotoToDelete(undefined);
                                }
                            }
                        );
                    }

                    setDeleteDialogPhotoOpen(false);
                }}
                title='Vymazať fotografiu'
                content='Naozaj chcete vymazať túto fotografiu? Táto akcia je
                nevratná.'
            />

            {initialIndex && !matchDownSm ? (
                <FullScreenImageViewer
                    initialImageIndex={initialIndex - 1}
                    images={post.images || null}
                    onClose={() => {
                        setInitialIndex(null);
                    }}
                />
            ) : null}
            {isLoading && <CenteredCircularProgress />}
            <Container maxWidth='md'>
                <Box
                    sx={{
                        paddingBottom: theme.spacing(4),
                    }}
                >
                    {isLoggedIn && (
                        <Grid container spacing={1} pt={theme.spacing(2)}>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    onClick={async () => {
                                        setIsEditable(!isEditable);
                                        if (!isEditable || body === post.body)
                                            return;

                                        const res = await editPost(postId!, {
                                            body,
                                        });

                                        if (res) {
                                            setPost({
                                                ...post,
                                                body,
                                            });
                                        }
                                    }}
                                >
                                    {isEditable ? (
                                        <SaveIcon />
                                    ) : (
                                        <EditIcon
                                            sx={{
                                                marginRight: theme.spacing(1),
                                            }}
                                        />
                                    )}
                                    {isEditable ? 'Uložiť' : 'Upraviť'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    onClick={async () => {
                                        const res = await editPost(postId!, {
                                            is_hidden: !post.is_hidden,
                                        });

                                        if (res) {
                                            setPost({
                                                ...post,
                                                is_hidden: !post.is_hidden,
                                            });
                                        }
                                    }}
                                >
                                    {!post.is_hidden ? (
                                        <VisibilityOffIcon
                                            sx={{
                                                marginRight: theme.spacing(1),
                                            }}
                                        />
                                    ) : (
                                        <VisibilityIcon
                                            sx={{
                                                marginRight: theme.spacing(1),
                                            }}
                                        />
                                    )}

                                    {!post.is_hidden ? 'Skryť' : 'Zobraziť'}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    color='error'
                                    onClick={() => {
                                        setDeleteDialogOpen(true);
                                    }}
                                >
                                    <DeleteIcon
                                        sx={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    />
                                    Zmazať
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    <Box
                        sx={{
                            py: theme.spacing(4),
                        }}
                    >
                        {isEditable ? (
                            <Box pt={3}>
                                <ReactQuill
                                    theme='snow'
                                    value={body}
                                    onChange={(value: string) => {
                                        setBody(value);
                                    }}
                                />
                            </Box>
                        ) : (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: post?.body,
                                }}
                            ></div>
                        )}

                        {isLoggedIn ? (
                            <Box pt={3}>
                                {matchDownMd ? (
                                    <MobileDatePicker
                                        label='Dátum príspevku'
                                        inputFormat='DD. MM. yyyy'
                                        value={date}
                                        onChange={(date: Moment | null) => {
                                            setDate(date);
                                            editPost(postId!, {
                                                created_at: Timestamp.fromDate(
                                                    date!.toDate()
                                                ),
                                            });
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                ) : (
                                    <DesktopDatePicker
                                        label='Dátum príspevku'
                                        inputFormat='DD. MM. yyyy'
                                        value={date}
                                        onChange={(date: Moment | null) => {
                                            setDate(date);
                                            console.log(date!);
                                            editPost(postId!, {
                                                created_at: Timestamp.fromDate(
                                                    date!.toDate()
                                                ),
                                            });
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                )}
                            </Box>
                        ) : (
                            // <Typography
                            //     variant='body2'
                            //     sx={{
                            //         marginTop: theme.spacing(2),
                            //     }}
                            // >
                            //     {date!.toLocaleDateString()}
                            // </Typography>
                            <></>
                        )}
                    </Box>

                    {(post.images || isLoggedIn) && (
                        <Typography variant='h4'>Fotogaléria</Typography>
                    )}
                    <ImageList
                        variant='masonry'
                        cols={matchDownSm ? 1 : 3}
                        gap={8}
                    >
                        {post.images?.map((image: Image, index: number) => (
                            <ImageListItem
                                key={`${image.imageURL}${Math.random()}`}
                                onClick={() =>
                                    !isEditable && setInitialIndex(index + 1)
                                }
                            >
                                {isEditable && (
                                    <RemoveCircleOutlineIcon
                                        fontSize={'large'}
                                        sx={{
                                            cursor: 'pointer',
                                            position: 'absolute',
                                            margin: '10px 0  0 10px',
                                            color: 'red',
                                        }}
                                        onClick={() => {
                                            setDeleteDialogPhotoOpen(true);
                                            setPhotoToDelete(image.imageURL);
                                        }}
                                    />
                                )}
                                <img src={image.imageURL} alt='post' />
                            </ImageListItem>
                        ))}
                        {isLoggedIn && (
                            <ImageListItem
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <ImagePicker
                                    postId={postId!}
                                    pagePath={path}
                                    post={post}
                                    setPost={setPost}
                                    setIsLoading={setIsLoading}
                                />
                            </ImageListItem>
                        )}
                    </ImageList>
                </Box>
            </Container>
        </>
    );
};

export default PostDetail;
