import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import {
    Box,
    Button,
    Container,
    Grid,
    ImageList,
    ImageListItem,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import { FileContent, useFilePicker } from 'use-file-picker';
import { createPost } from '../api/firestore';
import { uploadPhotoToStorage } from '../api/storage';
import useAuth from '../components/auth/useAuth';
import { theme } from '../themes/theme';
import { Post } from '../types/post';
import CenteredCircularProgress from '../components/CenteredCircularProgress';
import { motion } from 'framer-motion';
import PageAnimate from '../components/animations/PageAnimate';

const NewPost = () => {
    const location = useLocation();
    const { isLoggedIn } = useAuth();
    const [openFileSelector, { filesContent, loading, clear }] = useFilePicker({
        readAs: 'DataURL',
        multiple: true,
        accept: 'image/*',
    });

    const path = location.search.slice(1).split('=')[1];

    const [body, setBody] = useState<string>('');
    const [images, setImages] = useState<FileContent[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [date, setDate] = useState<Date | null>(new Date());

    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (filesContent.length > 0) {
            setIsLoading(true);
            setImages([...images, ...filesContent]);
            clear();
            setIsLoading(false);
        }
    }, [filesContent]);

    if (!isLoggedIn) return <></>;

    return (
        <>
            {isLoading && <CenteredCircularProgress />}
            <PageAnimate>
                <Container maxWidth='md'>
                    <Grid container spacing={1} pt={theme.spacing(2)}>
                        <Grid item>
                            <Button
                                variant='outlined'
                                onClick={async () => {
                                    console.log(images, body, date);
                                    setIsLoading(true);

                                    const urls = await uploadPhotoToStorage(
                                        images
                                    );

                                    const newPost: Post = {
                                        body,
                                        images: urls.map((url: string) => ({
                                            imageURL: url,
                                        })),
                                        path,
                                        created_at: Timestamp.fromDate(date!),
                                        is_hidden: false,
                                        is_pinned: false,
                                    };

                                    await createPost(newPost);
                                    setIsLoading(false);

                                    window.location.href = `/${path}`;
                                }}
                            >
                                <SaveIcon />
                                Uložiť
                            </Button>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            paddingBottom: theme.spacing(4),
                        }}
                    >
                        <Box
                            sx={{
                                py: theme.spacing(4),
                            }}
                        >
                            <Box pt={3}>
                                <ReactQuill
                                    theme='snow'
                                    value={body}
                                    onChange={(value: string) => {
                                        setBody(value);
                                    }}
                                />
                            </Box>

                            <Box pt={3}>
                                {matchDownMd ? (
                                    <MobileDatePicker
                                        label='Dátum príspevku'
                                        inputFormat='DD. MM. yyyy'
                                        value={date}
                                        onChange={(date: Date | null) =>
                                            setDate(date)
                                        }
                                        renderInput={(params: any) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                ) : (
                                    <DesktopDatePicker
                                        label='Dátum príspevku'
                                        inputFormat='DD. MM. yyyy'
                                        value={date}
                                        onChange={(date: Date | null) =>
                                            setDate(date)
                                        }
                                        renderInput={(params: any) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Typography variant='h4'>Fotogaléria</Typography>
                        <ImageList
                            variant='masonry'
                            cols={matchDownSm ? 1 : 3}
                            gap={8}
                        >
                            {images.map((image: FileContent, index: number) => (
                                <ImageListItem
                                    key={`${image.name}${Math.random()}`}
                                >
                                    <RemoveCircleOutlineIcon
                                        fontSize={'large'}
                                        sx={{
                                            cursor: 'pointer',
                                            position: 'absolute',
                                            margin: '10px 0  0 10px',
                                            color: 'red',
                                        }}
                                        onClick={() => {
                                            setImages(
                                                images.filter(
                                                    (_, i) => i !== index
                                                )
                                            );
                                        }}
                                    />

                                    <img src={image.content} alt='post' />
                                </ImageListItem>
                            ))}

                            <ImageListItem
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        console.log('add image');
                                        openFileSelector();
                                    }}
                                >
                                    <AddCircleIcon
                                        sx={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    />
                                    Pridať fotografiu
                                </Button>
                            </ImageListItem>
                        </ImageList>
                    </Box>
                </Container>
            </PageAnimate>
        </>
    );
};

export default NewPost;
