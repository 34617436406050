import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

type Props = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    content: string;
};

const DeleteDialog = (props: Props) => {
    const { open, onClose, onConfirm, title, content } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Nie
                </Button>
                <Button onClick={onConfirm} color='error'>
                    Áno
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
