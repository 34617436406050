import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../Firebase';

export const loginWithEmailAndPassword = async (
    email: string,
    password: string
) => {
    await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => {
    await signOut(auth);
};
