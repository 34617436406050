import { Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { Colors } from '../../themes/Colors';

type Props = {
    icon: React.ReactNode;
    hoverColor: string;
};

const Icon = (props: Props) => {
    const { icon, hoverColor } = props;
    return (
        <a
            href='https://www.facebook.com/hrdimalacania/'
            target='_blank'
            rel='noreferrer noopener'
        >
            <Typography
                component={motion.p}
                whileHover={{
                    scale: 1.2,
                    color: hoverColor,
                }}
                initial={{ color: Colors.grey80 }}
                sx={{
                    width: 40,
                }}
            >
                {icon}
            </Typography>
        </a>
    );
};

export default Icon;
