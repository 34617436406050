import { createContext, useEffect, useState } from 'react';
import { getSitesConfig } from '../../api/firestore';
import { Post } from '../../types/post';
import { Site } from '../../types/site';

type Props = {
    children: React.ReactNode;
};

type SiteContextType = {
    sites: Site[];
    setSites: (sites: Site[]) => void;
    posts: Post[];
    setPosts: (posts: Post[]) => void;
};

const SiteContext = createContext<SiteContextType>({
    sites: [],
    setSites: () => {},
    posts: [],
    setPosts: () => {},
});

const SiteProvider = (props: Props) => {
    const { children } = props;

    const [sites, setSites] = useState<Site[]>([]);
    const [posts, setPosts] = useState<Post[]>([]);

    useEffect(() => {
        getSitesConfig().then((sites: Site[]) => {
            setSites(sites);
        });
    }, []);

    return (
        <SiteContext.Provider
            value={{
                sites,
                setSites,
                posts,
                setPosts,
            }}
        >
            {children}
        </SiteContext.Provider>
    );
};

export default SiteContext;
export { SiteProvider };
