// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyAo4aTkVCQT-K_ZvsgWvBraT70PY4m8ZE4',
    authDomain: 'hrdi-malacania.firebaseapp.com',
    projectId: 'hrdi-malacania',
    storageBucket: 'hrdi-malacania.appspot.com',
    messagingSenderId: '415736293569',
    appId: '1:415736293569:web:a8387968891a1a80d04c40',
    measurementId: 'G-2NXFTZKM29',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
export default app;
