import { Box } from '@mui/material';
import React, { ComponentProps } from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../themes/Colors';
import { Post as PostType } from '../types/post';

type Props = ComponentProps<typeof Box> & {
    post: PostType;
};

const PicturePost = (props: Props) => {
    const { id, images } = props.post;
    const { sx } = props;

    return (
        <Box
            // component={NavLink}
            // to={id!}
            sx={{
                textDecoration: 'none',
                color: 'black',
                transition: 'color 0.2s ease-in-out',
                ...sx,
            }}
        >
            {images && images?.length > 0 && (
                <>
                    <img src={images[0].imageURL} alt='' width={'100%'} />
                </>
            )}
        </Box>
    );
};

export default PicturePost;
