import {
    deleteObject,
    getDownloadURL,
    ref,
    uploadBytes,
} from 'firebase/storage';
import { storage } from '../Firebase';
import { dataURLtoFile } from '../utils/dataURLtoFile';

export const uploadPhotoToStorage = async (
    images: { content: string; name: string }[]
) => {
    const promises = images
        .map(async (image) => {
            const file = await dataURLtoFile(image.content, image.name);
            const fileRef = ref(storage, `posts/${Date.now()}${image.name}`);
            const snapshot = await uploadBytes(fileRef, file);
            const url = await getDownloadURL(snapshot.ref);
            return url;
        })
        .map(async (promise) => {
            return await promise;
        })
        .map(async (url) => {
            return await url;
        });

    return [...(await Promise.all(promises))];
};

export const deletePhotoFromStorage = async (fileName: string) => {
    const file = ref(storage, fileName);

    return await deleteObject(file)
        .then(() => {
            console.log('Photo deleted');
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
};
