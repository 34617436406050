import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box } from '@mui/material';
import { ComponentProps, useState } from 'react';

type Props = ComponentProps<typeof Box> & {
    initialImageIndex: number | null;
    images: any[] | null;
    onClose: () => void;
};

const FullScreenImageViewer = (props: Props) => {
    const { initialImageIndex, images, onClose, ...otherProps } = props;

    const [imageIndex, setImageIndex] = useState(initialImageIndex || 0);

    if (!images) {
        return null;
    }

    const handleNext = () => {
        if (imageIndex + 1 >= images!.length) {
            setImageIndex(0);
            return;
        }
        setImageIndex(imageIndex + 1);
    };

    const handlePrevious = () => {
        if (imageIndex - 1 < 0) {
            setImageIndex(images!.length - 1);
            return;
        }
        setImageIndex(imageIndex - 1);
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                flexDirection: 'column',
            }}
            {...otherProps}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {images.length > 1 && (
                    <NavigateNextIcon
                        onClick={handlePrevious}
                        sx={{
                            cursor: 'pointer',
                            color: 'white',
                            padding: '4px',
                            transform: 'scale(2) rotate(180deg)',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '50%',
                            marginRight: '24px',
                        }}
                    />
                )}
                <img
                    src={images[imageIndex].imageURL}
                    alt='image'
                    className='fullscreen-image'
                />
                {images.length > 1 && (
                    <NavigateNextIcon
                        onClick={handleNext}
                        sx={{
                            cursor: 'pointer',
                            color: 'white',
                            padding: '4px',
                            transform: 'scale(2)',
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            borderRadius: '50%',
                            marginLeft: '24px',
                        }}
                    />
                )}
            </Box>
            <CloseIcon
                width={40}
                height={40}
                sx={{
                    cursor: 'pointer',
                    marginTop: '24px',
                    padding: '4px',
                    transform: 'scale(2)',
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    borderRadius: '50%',
                }}
                onClick={onClose}
            />
        </Box>
    );
};

export default FullScreenImageViewer;
