import { Box, CssBaseline } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import './App.css';
import CenteredCircularProgress from './components/CenteredCircularProgress';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import PostDetail from './components/PostDetail';
import useSites from './components/sites/useSites';
import './Firebase';
import About from './pages/About';
import AdminLogin from './pages/AdminLogin';
import NewPost from './pages/NewPost';
import TemplatePostPage from './pages/TemplatePostPage';
import { Site } from './types/site';

const Headers = () => {
    return (
        <Helmet>
            <meta charSet='utf-8' />
            <title>Hrdí Malačania</title>
        </Helmet>
    );
};

const App = () => {
    const { sites } = useSites();

    if (sites.length === 0) {
        return (
            <>
                <Headers />
                <CenteredCircularProgress />
            </>
        );
    }

    return (
        <div>
            <Headers />
            <CssBaseline />
            <Box
                display='grid'
                sx={{
                    minHeight: '100vh',
                    gridTemplateRows: 'auto 1fr auto',
                }}
            >
                <Router>
                    <Navbar />
                    {/* <AnimatePresence> */}
                    <Routes>
                        {sites.map((route: Site) => {
                            const children = route.children?.map(
                                (child: Site) => (
                                    <Route
                                        key={child.path}
                                        path={`${child.path}/:postId`}
                                        element={<PostDetail />}
                                    />
                                )
                            );

                            return (
                                <>
                                    {children}
                                    <Route
                                        key={route.path}
                                        path={`${route.path}/:postId`}
                                        element={<PostDetail />}
                                    />
                                </>
                            );
                        })}
                        {sites.map((route: any) => {
                            const children = route.children?.map(
                                (child: Site) => (
                                    <Route
                                        key={child.path}
                                        path={child.path}
                                        element={
                                            <TemplatePostPage route={child} />
                                        }
                                    />
                                )
                            );

                            if (route.path === 'about')
                                return (
                                    <>
                                        {children}
                                        <Route
                                            path='/about'
                                            element={<About />}
                                        />
                                    </>
                                );

                            return (
                                <>
                                    {children}
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        element={
                                            <TemplatePostPage route={route} />
                                        }
                                    />
                                </>
                            );
                        })}
                        <Route path='/admin' element={<AdminLogin />} />
                        <Route path='/new' element={<NewPost />} />
                        <Route
                            path='/'
                            element={<Navigate to='/domov' replace />}
                        />
                    </Routes>
                    {/* </AnimatePresence> */}
                </Router>
                <Footer />
            </Box>
        </div>
    );
};

export default App;
