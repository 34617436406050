import { Typography, Menu, MenuItem, Box, Fade } from '@mui/material';
import { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Colors } from '../../themes/Colors';
import { Site } from '../../types/site';
import { isActive } from '../../utils/isActive';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Link from './Link';

const DropdownMenu = (props: { sites: Site }): ReactElement => {
    const { sites } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            }}
        >
            <Link to={sites.path} isActive={isActive(sites.path.split('/')[0])}>
                {sites.title}
            </Link>
            <Typography
                color={isActive(sites.path) ? Colors.themedBlue : Colors.black}
                onMouseEnter={handleOpen}
                // onMouseLeave={handleClose}
                onClick={handleOpen}
                sx={{
                    padding: '0',
                    color: isActive(sites.path.split('/')[0])
                        ? Colors.themedBlue
                        : Colors.black,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '&:hover': {
                        color: Colors.themedBlue,
                    },
                }}
            >
                <ArrowDropDownIcon />
            </Typography>
            <Menu
                id={`navbar-menu-${sites.path}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                MenuListProps={{
                    onMouseLeave: handleClose,
                }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                TransitionComponent={Fade}
                elevation={0}
                sx={{
                    '& .MuiMenu-paper': {
                        border: `1px solid ${Colors.grey50}`,
                        borderRadius: 3,
                    },
                }}
            >
                {sites.children?.map((site: Site, index: number) => {
                    return (
                        <MenuItem
                            key={site.path}
                            component={NavLink}
                            to={site.path}
                            sx={{
                                color: isActive(site.path)
                                    ? Colors.themedBlue
                                    : Colors.black,
                                '&:hover': {
                                    backgroundColor: `${Colors.themedGreen}10`,
                                    '& a': {
                                        color: Colors.themedGreen,
                                        paddingLeft: 0,
                                    },
                                },
                            }}
                        >
                            <Link
                                to={site.path}
                                isActive={isActive(site.path.split('/')[1])}
                                sx={{
                                    paddingLeft: 0,
                                }}
                            >
                                {site.title}
                            </Link>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
};

export default DropdownMenu;
