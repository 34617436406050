import { ComponentProps } from 'react';

import { Link as MuiLink } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Colors } from '../../themes/Colors';

type Props = ComponentProps<typeof MuiLink> &
    ComponentProps<typeof NavLink> & {
        isActive?: boolean;
    };

const Link = (props: Props) => {
    const { isActive, ...otherProps } = props;
    return (
        <MuiLink
            component={NavLink}
            underline='none'
            variant='button'
            color={isActive ? Colors.themedBlue : 'black'}
            sx={{
                fontSize: 'large',
                marginLeft: '2rem',
                '&:hover': {
                    color: Colors.themedGreen,
                },
                transition: 'color 0.2s ease-in-out',
            }}
            {...otherProps}
        />
    );
};

export default Link;
