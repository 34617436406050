import { Box, Typography, Container } from '@mui/material';
import { motion } from 'framer-motion';
import PageAnimate from '../components/animations/PageAnimate';
import { Colors } from '../themes/Colors';

const About = () => {
    return (
        <PageAnimate>
            <Container maxWidth='md'>
                <Typography variant='h3' my={3}>
                    O nás
                </Typography>
                <Typography
                    fontWeight={'bold'}
                    color={Colors.themedBlue}
                    display={'inline'}
                >
                    Hrdo.{' '}
                </Typography>
                <Typography
                    fontWeight={'bold'}
                    color={Colors.themedYellow}
                    display={'inline'}
                >
                    Nahlas.{' '}
                </Typography>
                <Typography
                    fontWeight={'bold'}
                    color={Colors.themedGreen}
                    display={'inline'}
                >
                    Zodpovedne.{' '}
                </Typography>
                <Typography variant='body1' display={'flex'}>
                    <br />
                    To boli prvé heslá, s ktorými sme sa ako Hrdí Malačania v
                    roku 2013 spoločne rozhodli pracovať na zlepšovaní a
                    skvalitňovaní života v našom krásnom meste Malacky. Počas
                    rokov sme sa prepracovali k množstvu akcií, prednášok a
                    spoločenských udalostí, ktoré sú vykonávané najmä na
                    sídlisku Juh. Neostali sme však iba na sídlisku Juh. V rámci
                    okresu Malacky sme našu činnosť, zameranú najmä na prednášky
                    určené pre deti, ale aj pre seniorov, odprezentovali vo
                    viacerých obciach.
                    <br />
                    <br />
                    Táto stránka je malým ohliadnutím a zhrnutím toho, čo sa nám
                    podarilo za roky činnosti zlepšiť, vybudovať, ale aj potešiť
                    akciami zameranými najmä pre deti. Taktiež sa však nechceme
                    obracať iba za minulosťou, ale predstaviť naše vízie a plány
                    na rozvoj nielen sídliska Juh, ale i celých Malaciek.
                    <br />
                    <br />
                    Nájdete tu množstvo fotografií z akcií, pričom sa mnohí z
                    Vás sa na fotkách určite nájdu a pospomínajú na časy minulé,
                    pri ktorých vsak neostávame a ideme ďalej s našim heslom.
                    Hrdo - Nahlas - Zodpovedne.
                    <br />
                    <br />
                    <br />
                    Hrdí Malačania Róbert Peťko a Štefan Hronček
                </Typography>
            </Container>
        </PageAnimate>
    );
};

export default About;
