import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar,
    Box,
    Container,
    Divider,
    Drawer,
    Icon,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { FC, ReactElement, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Colors } from '../themes/Colors';
import { theme } from '../themes/theme';
import { Site } from '../types/site';
import { isActive } from '../utils/isActive';
import useAuth from './auth/useAuth';
import DropdownMenu from './navigation/DropdownMenu';
import Link from './navigation/Link';
import useSites from './sites/useSites';

const MobileLink = ({
    site,
    fontSize,
    setIsMenuOpen,
    isActive,
}: {
    site: Site;
    fontSize: string;
    setIsMenuOpen: (isOpen: boolean) => void;
    isActive: boolean;
}) => {
    return (
        <Link
            to={site.path}
            isActive={isActive}
            sx={{
                paddingLeft: 0,
                fontSize: { fontSize },
                textAlign: 'center',
                paddingBottom: '1rem',
            }}
            onClick={() => {
                setIsMenuOpen(false);
            }}
        >
            {site.title}
        </Link>
    );
};

const Navbar: FC = (): ReactElement => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const { sites } = useSites();

    const location = useLocation();
    const { isLoggedIn } = useAuth();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setIsMenuOpen(true);
    };

    const handleCloseNavMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <>
            <AppBar
                position='sticky'
                sx={{
                    backgroundColor: Colors.white,
                }}
                elevation={0}
            >
                <Container maxWidth='md'>
                    <Toolbar disableGutters>
                        {/* MOBILE!!! */}
                        <Box
                            sx={{
                                display: { xs: 'flex', lg: 'none' },
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: Colors.grey70,
                                }}
                                aria-label='account of current user'
                                aria-controls='menu-appbar'
                                aria-haspopup='true'
                                onClick={
                                    isMenuOpen
                                        ? handleCloseNavMenu
                                        : handleOpenNavMenu
                                }
                            >
                                {isMenuOpen ? (
                                    <CloseIcon
                                        sx={{
                                            transform: 'scale(1.2)',
                                        }}
                                    />
                                ) : (
                                    <MenuIcon
                                        sx={{
                                            transform: 'scale(1.2)',
                                        }}
                                    />
                                )}
                            </Box>
                            <Typography
                                variant='h6'
                                noWrap
                                component={NavLink}
                                to='/'
                                sx={{
                                    mr: 2,
                                    display: 'flex',
                                    textDecoration: 'none',
                                    color: Colors.grey80,
                                    fontWeight: 'light',
                                }}
                            >
                                <Icon
                                    sx={{
                                        display: 'flex',
                                        mr: 1,
                                        alignSelf: 'center',
                                    }}
                                >
                                    <img src='/logo.svg' alt='logo' />
                                </Icon>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        color: Colors.themedBlue,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    hrdí
                                </Typography>
                                &nbsp;malačania
                                {isLoggedIn ? (
                                    <Typography variant='h6'>
                                        &nbsp;admin
                                    </Typography>
                                ) : null}
                            </Typography>
                        </Box>
                        <Drawer
                            anchor='left'
                            open={isMenuOpen}
                            onClose={handleCloseNavMenu}
                        >
                            <Box
                                sx={{
                                    width: '80vw',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: Colors.grey70,
                                    paddingVertical: '1rem',
                                }}
                            >
                                <Typography
                                    variant='h6'
                                    noWrap
                                    component={NavLink}
                                    to='/'
                                    sx={{
                                        mr: 2,
                                        display: 'flex',
                                        textDecoration: 'none',
                                        color: Colors.grey80,
                                        fontWeight: 'light',
                                        padding: '1.5rem 0',
                                    }}
                                >
                                    <Icon
                                        sx={{
                                            display: 'flex',
                                            mr: 1,
                                            alignSelf: 'center',
                                        }}
                                    >
                                        <img src='/logo.svg' alt='logo' />
                                    </Icon>
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            color: Colors.themedBlue,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        hrdí
                                    </Typography>
                                    &nbsp;malačania
                                </Typography>
                                {sites.map((site: Site) => {
                                    if (site.path === 'domov') return null;

                                    const rootLink = (
                                        <MobileLink
                                            key={site.path}
                                            fontSize={'1.5rem'}
                                            setIsMenuOpen={setIsMenuOpen}
                                            site={site}
                                            isActive={isActive(site.path)}
                                        />
                                    );

                                    if (site.children) {
                                        return (
                                            <>
                                                {rootLink}

                                                {site.children.map(
                                                    (child: Site) => {
                                                        return (
                                                            <MobileLink
                                                                key={child.path}
                                                                site={child}
                                                                isActive={isActive(
                                                                    child.path
                                                                )}
                                                                fontSize={
                                                                    '1.2rem'
                                                                }
                                                                setIsMenuOpen={
                                                                    setIsMenuOpen
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                                <Divider />
                                            </>
                                        );
                                    }

                                    return (
                                        <>
                                            {rootLink}
                                            <Divider />
                                        </>
                                    );
                                })}
                                {isLoggedIn ? (
                                    <MobileLink
                                        site={
                                            {
                                                title: 'Admin',
                                                path: '/admin',
                                                children: [],
                                            } as Site
                                        }
                                        isActive={isActive('admin')}
                                        fontSize={'1.5rem'}
                                        setIsMenuOpen={setIsMenuOpen}
                                    />
                                ) : null}
                            </Box>
                        </Drawer>

                        {/* END MOBILE */}
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', lg: 'flex' },
                                flexWrap: 'wrap',
                                padding: '8px 0',
                            }}
                        >
                            <Typography
                                variant='h6'
                                noWrap
                                component={NavLink}
                                to='/'
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    textDecoration: 'none',
                                    color: Colors.grey80,
                                    fontWeight: 'light',
                                }}
                            >
                                <Icon
                                    sx={{
                                        display: { xs: 'none', md: 'flex' },
                                        mr: 1,
                                        alignSelf: 'center',
                                    }}
                                >
                                    <img src='/logo.svg' alt='logo' />
                                </Icon>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        color: Colors.themedBlue,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    hrdí
                                </Typography>
                                &nbsp;malačania
                                {isLoggedIn ? (
                                    <Typography variant='h6'>
                                        &nbsp;admin
                                    </Typography>
                                ) : null}
                            </Typography>

                            {sites.map((page: any) =>
                                page.children.length > 0 ? (
                                    <DropdownMenu
                                        key={page.path}
                                        sites={page}
                                    />
                                ) : (
                                    page.path !== 'domov' && (
                                        <Link
                                            key={page.path}
                                            to={page.path}
                                            isActive={isActive(page.path)}
                                        >
                                            {page.title}
                                        </Link>
                                    )
                                )
                            )}
                            {isLoggedIn ? (
                                <Link to='/admin' isActive={isActive('admin')}>
                                    Admin
                                </Link>
                            ) : null}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
};

export default Navbar;
