import { Box, Container, Typography, Grid } from '@mui/material';
import { Colors } from '../themes/Colors';
import FacebookIcon from '@mui/icons-material/Facebook';
import { motion } from 'framer-motion';
import Icon from './animations/Icon';

const Footer = () => {
    return (
        <Box
            sx={{
                // position: 'absolute',
                bottom: 0,
                width: '100vw',
                textAlign: 'center',
                backgroundColor: Colors.grey20,
                padding: 2,
                color: Colors.grey80,
            }}
        >
            <Container maxWidth='md'>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant='body1'>
                            Sledujte nás na:
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            py={1}
                        >
                            <Icon
                                icon={
                                    <FacebookIcon
                                        sx={{
                                            fontSize: 40,
                                        }}
                                    />
                                }
                                hoverColor={Colors.facebook}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>&copy; 2022 Hrdí Malačania</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
