import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';
import { db } from '../Firebase';
import { Post } from '../types/post';
import { Site } from '../types/site';

export const getSitesConfig = async () => {
    const q = doc(db, 'siteConfig', 'config');

    const siteData = await getDoc(q);

    return siteData.data()!.sites;
};

export const getPosts = async (key: string) => {
    const postRef = collection(db, 'posts');

    const q = query(postRef, where('path', '==', key));

    const postsSnapshot = await getDocs(q);

    const posts: Post[] = [];

    postsSnapshot.forEach((doc) => {
        posts.push({ ...(doc.data()! as Post), id: doc.id });
    });

    return posts;
};

export const getPostById = async (id: string) => {
    const docRef = doc(db, 'posts', id);

    const post = await getDoc(docRef);

    return post.data()! as Post;
};

export const createPost = async (post: Post) => {
    const postsRef = collection(db, 'posts');

    await addDoc(postsRef, post);
};

export const editPost = async (id: string, value: any) => {
    const postRef = doc(db, 'posts', id);

    return updateDoc(postRef, value)
        .then(() => {
            console.log('Post updated');
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
};

export const deletePost = async (id: string) => {
    const postRef = doc(db, 'posts', id);

    return await deleteDoc(postRef)
        .then(() => {
            console.log('Post deleted');
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
};

export const setSitesConfig = async (sites: Site[]) => {
    const q = doc(db, 'siteConfig', 'config');

    return setDoc(q, { sites });
};
